import React from 'react'
import { connect } from 'react-redux'

import Layout from 'layout'
import * as actionCreators from '../../store/actions'

const queryString = require('query-string')

export class AuthCallbackPage extends React.Component {
  componentDidMount = async () => {
    const { exchangeOauthCodeAndUpdateStore } = this.props

    const oidcFlow = process.env.oidcFlow !== undefined ? JSON.parse(process.env.oidcFlow) : false
    const redirectUri = process.env.oidcRedirectUri
    const parsedString = queryString.parse(window.location.search)

    if (oidcFlow) {
      await exchangeOauthCodeAndUpdateStore(parsedString.code, parsedString.state, redirectUri)
    }

    const { authProcess } = this.props
    // Redirect to the homepage
    if (authProcess.userNotEnabled) {
      window.location.replace('/?waiting_for_approval=1')
    } else {
      window.location.replace('/')
    }
  }

  render() {
    return <Layout />
  }
}

const mapStateToProps = state => {
  return {
    user: state.authentication.user,
    authProcess: state.authentication.authProcess,
  }
}

export default connect(mapStateToProps, actionCreators)(AuthCallbackPage)
